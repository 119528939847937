import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column; /* Ensures elements are stacked vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Matte black background */
  padding: 20px; /* Add some padding to avoid edges on smaller screens */
`;

const LogoImage = styled(motion.img)`
  width: 500px; /* Default size for larger screens */
  height: auto;

  @media (max-width: 768px) {
    width: 400px; /* Adjust the size for tablets */
  }

  @media (max-width: 480px) {
    width: 300px; /* Adjust the size for mobile devices */
  }
`;

const ContactButton = styled(motion.button)`
  margin-top: 3px; /* Add more margin to push the button further below the logo */
  padding: 15px 25px;
  border: none;
  border-radius: 50px;
  background-color: #fff;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;

  font-family: 'NexaBold', sans-serif;

  @media (max-width: 768px) {
    padding: 12px 20px; /* Slightly smaller padding on tablets */
    font-size: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px 18px; /* Smaller padding on mobile */
    font-size: 14px;
  }

  &:hover {
    background-color: #f1f1f1;
    color: #333;
  }

  &:focus {
    outline: none;
  }
`;


const UnderConstructionText = styled(motion.p)`
  margin-top: 40px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
  font-family: 'NexaBold', sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;


const AnimatedLogo = () => {
  return (
    <LogoContainer>
      <LogoImage
        src="/logo.png"
        alt="MAKDIS Logo"
        // Initial intro animation
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{
          opacity: 1,
          scale: 1
          // rotate: [0, 360], // Adding rotation for the loop
        }}
        transition={{
          duration: 1, // Slightly longer duration for more impact
          ease: 'easeInOut', // Using a different easing function
          loop: Infinity, // Loop the animation
          repeatDelay: 2, // Delay between loops
        }}
        // Looping animation (e.g., pulsing)
        whileHover={{ scale: 1.1 }} // Optional: make it slightly interactive
      />
      <UnderConstructionText
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.05, duration: 0.5, ease: 'easeInOut' }}>
        Our website is currently under construction as we prepare to launch something great. <br />
        In the meantime, we're here to help! Please feel free to reach out to us:
      </UnderConstructionText>
      <ContactButton
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 2.0, duration: 0.5, ease: 'easeInOut' }}
        onClick={() => window.location.href = 'mailto:contact@makdis.io'}
      >
        CONTACT
      </ContactButton>
    </LogoContainer>
  );
};

export default AnimatedLogo;
